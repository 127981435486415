body,
html,
#root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  background-color: #161616;
  font-family: 'Jost', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: 'Jost', 'Helvetica Neue', sans-serif;
}

input {
  font-family: 'Jost', 'Helvetica Neue', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* chart bottom axis styles */
text[dominant-baseline='text-before-edge'] {
  font-size: 12px !important;
  font-family: 'Courier New';
}
